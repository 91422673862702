exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bias-mitigation-js": () => import("./../../../src/pages/bias-mitigation.js" /* webpackChunkName: "component---src-pages-bias-mitigation-js" */),
  "component---src-pages-blog-categories-contentful-blog-category-slug-js": () => import("./../../../src/pages/blog/categories/{contentfulBlogCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-categories-contentful-blog-category-slug-js" */),
  "component---src-pages-blog-contentful-blog-posts-slug-js": () => import("./../../../src/pages/blog/{contentfulBlogPosts.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-posts-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contentful-legal-pages-slug-js": () => import("./../../../src/pages/{contentfulLegalPages.slug}.js" /* webpackChunkName: "component---src-pages-contentful-legal-pages-slug-js" */),
  "component---src-pages-contentful-marketing-landing-slug-js": () => import("./../../../src/pages/{contentfulMarketingLanding.slug}.js" /* webpackChunkName: "component---src-pages-contentful-marketing-landing-slug-js" */),
  "component---src-pages-ethics-js": () => import("./../../../src/pages/ethics.js" /* webpackChunkName: "component---src-pages-ethics-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-news-contentful-news-slug-js": () => import("./../../../src/pages/news/{contentfulNews.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-news-slug-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-platform-js": () => import("./../../../src/pages/our-platform.js" /* webpackChunkName: "component---src-pages-our-platform-js" */),
  "component---src-pages-retention-prediction-js": () => import("./../../../src/pages/retention-prediction.js" /* webpackChunkName: "component---src-pages-retention-prediction-js" */),
  "component---src-pages-talent-discovery-js": () => import("./../../../src/pages/talent-discovery.js" /* webpackChunkName: "component---src-pages-talent-discovery-js" */),
  "component---src-pages-talent-mobility-js": () => import("./../../../src/pages/talent-mobility.js" /* webpackChunkName: "component---src-pages-talent-mobility-js" */)
}

